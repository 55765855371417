import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal'

import SubmitButton from 'uiKit/buttons/SubmitButton'
import { AlertCircleIcon } from 'uiKit/icons/AlertCircleIcon'
import { AtomDeleteDataType } from 'models/AtomDeleteDataType'
import * as S from './DeleteAtomAlertModal.style'

interface Props {
  atom: any
  handleClose: () => void
  details: AtomDeleteDataType
}

const CONNECTIONS = [
  { property: 'atoms', title: 'atoms' },
  { property: 'faqs', title: 'FAQs' },
  { property: 'triggers', title: 'triggers' },
  { property: 'menus', title: 'Main Menu items' },
  { property: 'greetings', title: 'Greetings' },
  { property: 'broadcasts', title: 'Broadcasts' },
  { property: 'aiActions', title: 'AI Actions' },
]

export const DeleteAtomAlertModal: React.FC<Props> = ({ details, atom, handleClose }) => {
  const [showDetails, setShowDetails] = useState(false)

  const renderConnected = (property, title) => {
    const count = details?.[property]?.length
    if (!count) {
      return
    }
    const names = details?.[property]
    return (
      <>
        <S.Label>
          Connected {title} ({count}):
        </S.Label>
        {names.map(name => (
          <S.Item key={name}>{name}</S.Item>
        ))}
      </>
    )
  }

  return (
    <Modal open={true} onClose={handleClose}>
      <S.Container>
        <AlertCircleIcon />
        <S.Title>Atom "{atom.name}" cannot be deleted because it is used in chatbot logic</S.Title>
        {showDetails && (
          <S.List>{CONNECTIONS.map(connection => renderConnected(connection.property, connection.title))}</S.List>
        )}
        <S.ButtonsWrap>
          {!showDetails && <SubmitButton empty title="Details" onClick={() => setShowDetails(true)} />}
          <SubmitButton onClick={handleClose} title="Ok" />
        </S.ButtonsWrap>
      </S.Container>
    </Modal>
  )
}
