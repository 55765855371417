import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Heading from '../../../../../uiKit/texts/Heading'
import Input from '../../../../../uiKit/inputs/Input'
import { deepCopyFunction } from '../../../../../helpers/deepCopyFunction'
import { updateBot } from '../../../../home/api/bots'
import SubmitButton from '../../../../../uiKit/buttons/SubmitButton'

import { styles } from './styles'
import { MAX_BOT_NAME_LENGTH } from '../../../../home/components/NewBotModal'

const NameBlock = ({ classes, activeBot }) => {
  const [botName, setBotName] = useState('')

  useEffect(() => {
    setBotName(activeBot.name)
  }, [activeBot])

  const handleChangeName = event => {
    setBotName(event.target.value)
  }

  const handleUpdate = () => {
    const bot = deepCopyFunction(activeBot)
    bot.name = botName

    updateBot(bot)
  }

  return (
    <div className={classes.container}>
      <Heading>Edit chatbot's name</Heading>
      <Input
        title="New chatbot's name"
        style={{ width: 300 }}
        value={botName}
        maxLength={MAX_BOT_NAME_LENGTH}
        placeholder="Enter bot Name"
        onChange={handleChangeName}
      />
      <SubmitButton styles={{ marginTop: 16 }} title="Update" onClick={handleUpdate} />
    </div>
  )
}

NameBlock.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default withStyles(styles)(connect(mapStateToProps)(NameBlock))
