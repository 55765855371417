import React from 'react'
import classes from './styles.module.scss'

interface LabelProps {
  children: React.ReactNode
}

const Label = ({ children }: LabelProps): JSX.Element => {
  return <p className={classes.label}>{children}</p>
}

export default Label
