import {
  ADD_CONTEXT,
  DELETE_CONTEXT,
  SAVE_CONTEXTS,
  UPDATE_CONTEXT,
  CLEAR_CONTEXTS,
  RESET_PREVIOUS_CONTEXT,
} from '../actions/contexts'

const contexts = (state = null, action) => {
  switch (action.type) {
    case SAVE_CONTEXTS:
      return action.contexts.reverse()
    case ADD_CONTEXT:
      const context = [...state]
      context.unshift(action.context)
      return context
    case UPDATE_CONTEXT:
      const contexts = [...state]
      contexts.forEach((element, index) => {
        if (element.id === action.context.id) {
          contexts[index] = action.context
        }
      })
      return contexts
    case DELETE_CONTEXT:
      return [...state].filter(x => {
        return x.id !== action.contextId
      })
    case CLEAR_CONTEXTS:
      return null
    case RESET_PREVIOUS_CONTEXT:
      const existingContexts = [...state]
      const targetContextIndex = existingContexts.findIndex(c => c.id === action.contextId)
      if (targetContextIndex !== -1) {
        const withoutPreviousContext = { ...existingContexts[targetContextIndex], previousAtom: null }
        existingContexts.splice(targetContextIndex, 1, withoutPreviousContext)
        return [...existingContexts]
      }
      return [...state]
    default:
      return state
  }
}

export default contexts
