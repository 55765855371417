
import { BASE_URL } from '../../../../configs'
import {
  alertError,
  createJsonHeaders,
  logoutOnRequestOrResponseBlob,
} from '../../../../api'

export async function getCallRecording(botId, chatId, conversationId) {
  const url = `${BASE_URL}/bot/${botId}/support/user/${chatId}/conversations/${conversationId}/recording`
  try {
    const response = await fetch(url, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET',
    })
    return await logoutOnRequestOrResponseBlob(response)
  } catch (e) {
    alertError('Failed to load call recording.')
  }
}
