import { GET_TWILIO_CREDENTIALS, RESET_TWILIO_CREDENTIALS } from '../actions/twilioCredentials'

export interface ITwilioCredentials {
  connected: boolean
  accountSID?: string
  authToken?: string
  phone?: string
  apiBaseUrl?: string
  authTokenSID?: string
}

const twilioCredentials = (
  state: ITwilioCredentials = { connected: false },
  action: { type: string; twilioCredentials: ITwilioCredentials },
): ITwilioCredentials | Record<string, unknown> => {
  switch (action.type) {
    case GET_TWILIO_CREDENTIALS:
      return action.twilioCredentials

    case RESET_TWILIO_CREDENTIALS:
      return {}

    default:
      return state
  }
}

export default twilioCredentials
