import React from 'react'

import * as S from './BotModeRadioButton.style'

import { NewBotModeMap } from './constants'

const options = [
  {
    label: 'Text',
    value: NewBotModeMap.Text,
  },
  {
    label: 'Voice',
    value: NewBotModeMap.Voice,
  },
]

interface Props {
  value: string
  onChange: (a: string) => void
}

const BotModeRadioButton: React.FC<Props> = ({ value, onChange }) => {
  return (
    <S.BotModeRadioButton>
      {options.map(option => (
        <S.Option key={option.value} isActive={option.value === value} onClick={() => onChange(option.value)}>
          {option.label}
        </S.Option>
      ))}
    </S.BotModeRadioButton>
  )
}

export default BotModeRadioButton
