import React, { FC, useContext, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import AttributeSelect from '../../../../uiKit/AttributeSelect/AttributeSelect'
import classes from './styles.module.scss'
import { CHATBOT, DEFAULT } from '../../../../constants/attributeTypes'
import MessageTemplate from '../MessageTemplate/MessageTemplate'
import { MessagesContext } from '../MessagesWrap/MessagesWrap'

interface Attribute {
  id?: string
  name?: string
}

interface Message {
  getKnowledge: {
    searchQueryAttribute?: Attribute
    searchResultAttribute?: Attribute
  }
}

interface OptionType {
  value: string
  label: string
}

interface OwnProps {
  message: Message
  updateMessage: (message: Message) => void
  onDelete: () => void
}

interface ReduxState {
  attributes: any
  activeBot?: { id: string }
}

const mapStateToProps = (state: ReduxState) => ({
  attributes: state.attributes,
  activeBotId: state.activeBot?.id,
})

const connector = connect(mapStateToProps)
type ReduxProps = ConnectedProps<typeof connector>

type Props = OwnProps & ReduxProps & RouteComponentProps

const GetKnowledge: FC<Props> = props => {
  const { message, updateMessage, onDelete } = props
  const { saveTime } = useContext(MessagesContext)
  const [focus, setFocus] = useState<number | false>(false)

  const handleUpdateSearchAttribute = (option: OptionType, searchQuery: boolean) => {
    const newGetKnowledge = deepCopyFunction(message)
    if (searchQuery) {
      newGetKnowledge.getKnowledge.searchQueryAttribute = { id: option.value, name: option.label }
    } else {
      newGetKnowledge.getKnowledge.searchResultAttribute = { id: option.value, name: option.label }
    }
    updateMessage({ ...newGetKnowledge })
    setFocus(false)
  }

  return (
    <MessageTemplate
      title="Get knowledge"
      tooltipText="Use a query from one attribute to fetch knowledge into another chatbot attribute."
      onDelete={onDelete}>
      <div className={classes.body}>
        <div className={classes.labels}>
          <p>Get search query from:</p>
          <p>Set search result into:</p>
        </div>
        <div className={classes.attributeWrap}>
          {[message?.getKnowledge?.searchQueryAttribute, message?.getKnowledge?.searchResultAttribute].map(
            (attribute, index) => (
              <AttributeSelect
                key={attribute?.id || index}
                focus={focus === index || !attribute?.id}
                value={attribute?.id}
                onChange={(option: OptionType) => handleUpdateSearchAttribute(option, index == 0)}
                onBlur={() => setFocus(false)}
                onClick={() => setFocus(index)}
                required
                displayOptions={[DEFAULT, CHATBOT]}
                containerStyle={{ width: '50%' }}
                isClearable={false}
                defaultAttributes={['language', 'close_conversation', 'conversation_outcome']}
                saveTime={saveTime}
                index={index}
              />
            ),
          )}
        </div>
      </div>
    </MessageTemplate>
  )
}

export default GetKnowledge
