import React from 'react'
import { connect } from 'react-redux'
import Modal from '@material-ui/core/Modal'
import { Close } from '@material-ui/icons'
import UserAttributes from '../UserAttributes/UserAttributes'

const UserProfileModal = ({ activeUser, open, onClose }) => {
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      style={{ display: 'flex' }}
      onClose={onClose}>
      <div
        className="d-flex flex-column m-auto overflow-y-scroll bg-light shadow"
        style={{ width: '480px', height: '80%', borderRadius: '10px' }}>
        <div className="d-flex justify-content-between px-4 py-4">
          <div>
            <h2 className="mb-0" style={{ color: 'var(--color-oxford-blue)', fontSize: '20px' }}>
              {activeUser.firstName} {activeUser.lastName}
            </h2>
          </div>
          <Close onClick={onClose} cursor="pointer" />
        </div>
        <div className="flex-grow-1" style={{ minHeight: 0 }}>
          <UserAttributes />
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = state => ({
  activeUser: state.activeUser,
})

export default connect(mapStateToProps)(UserProfileModal)
