import { showSnackBar } from '../tabs/home/actions/snackBar'
import { authenticate } from '../tabs/authorization/actions/security'
import { logout } from '../tabs/authorization/api/admin'
import { isError } from '../tabs/authorization/actions/forbiddenError'

export class NotFoundError extends Error {
  constructor(message = '', ...args) {
    super(message, ...args)
    this.name = 'NotFoundError'
  }
}

export function createJsonHeaders() {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json;charset=UTF-8')
  return headers
}

export async function logoutOnRequestOrResponseJson(response) {
  if (response.ok) {
    if (response.redirected) {
      return authenticate()
    } else {
      const contentType = response.headers.get('content-type')
      const isJSON = contentType && contentType.indexOf('application/json') !== -1

      return isJSON ? response.json() : response.text()
    }
  }
  return handleError(response)
}

const handleError = async response => {
  handleErrorStatusCodes(response)

  const error = await response?.json()?.then(res => res?.error)
  const errorMessage = error?.message
  throw new Error(errorMessage || 'Not 2xx response')
}

const handleErrorStatusCodes = response => {
  if (response.status === 307) {
    verifyPasswordChangeNeeded(response)
  } else if (response.status === 401) {
    logout().then(() => alertError('You’ve been logged out. Please log back in.'))
  } else if (response.status === 403) {
    isError()
  }
}

export function logoutOnRequestOrResponseBlob(response) {
  if (response.ok) {
    return response.redirected ? authenticate() : response.blob()
  } else if (response.status === 307) {
    verifyPasswordChangeNeeded(response)
  } else if (response.status === 401 || response.status === 403) {
    logout()
  }
  throw new Error('Not 2xx response')
}

function verifyPasswordChangeNeeded(response) {
  if (response.headers.get('Require-Password-Change')) {
    logout()
      .then(() => {
        window.location.href = response.headers.get('Password-Reset-Url')
      })
      .then(() => alertError('Your password needs to be changed.'))
  }
}

export function alertSuccess(message, duration) {
  showSnackBar({
    open: true,
    text: message,
    type: 'success',
    duration,
  })
}

export const pasteCleanTextContent = e => {
  e.preventDefault()
  document.execCommand('insertText', false, e.clipboardData.getData('text/plain'))
}

export const paramsPattern = /{{[^{]*?}}/g
export const ifStringEmpty = text => {
  if (text == null) return true
  text = text.trim()
  return text.length === 0 && !text.trim() && text.trim() !== ' '
}

export function alertError(message) {
  showSnackBar({
    open: true,
    text: message,
    type: 'error',
  })
}

export function alertWarning(message) {
  showSnackBar({
    open: true,
    text: message,
    type: 'warning',
  })
}

export function customResponseParser(response) {
  if (response.hasOwnProperty('data')) {
    return response.data
  }

  if (response?.error) {
    throw response?.error
  }
}
