import React, { useState } from 'react'
import JSONPretty from 'react-json-pretty'

import classes from './styles.module.scss'

interface Props {
  callArguments: { name: string; value: string }[]
  callResult: string
}

type TabType = 'Arguments' | 'Result'
const tabTypeMap: Record<TabType, TabType> = {
  Arguments: 'Arguments',
  Result: 'Result',
}

const isValidJSON = str => {
  try {
    JSON.parse(str)
    return true
  } catch (error) {
    return false
  }
}

export const AiActionMessageTab: React.FC<Props> = ({ callArguments, callResult }) => {
  const [selectedTab, setSelectedTab] = useState(tabTypeMap.Arguments)

  const handleSelectTab = (tab: TabType) => {
    setSelectedTab(tab)
  }

  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <div className={classes.tabs}>
          {Object.values(tabTypeMap).map((tab, index) => (
            <div
              key={index}
              className={selectedTab === tab ? classes.activeTab : classes.tab}
              onClick={() => handleSelectTab(tab)}>
              {tab}
            </div>
          ))}
        </div>
        <div className={classes.content}>
          {selectedTab === tabTypeMap.Arguments && (
            <div className={classes.callArgumentsContainer}>
              {callArguments.map((arg, index) => (
                <div key={index} className={classes.argument}>
                  <span style={{ wordBreak: 'break-all' }}>Name: {arg.name}</span>
                  <span style={{ wordBreak: 'break-all' }}>Value: {arg.value}</span>
                </div>
              ))}
            </div>
          )}

          {selectedTab === tabTypeMap.Result && (
            <div style={{ maxHeight: '250px' }}>
              {isValidJSON(callResult) ? (
                <JSONPretty id="json-pretty" data={JSON.parse(callResult)} />
              ) : (
                <div className={classes.callResult}>{callResult || "'last_ai_action_result' value was not set"}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
