import React from 'react'
import { connect } from 'react-redux'

import { KnowledgeBaseSubTabMap } from 'tabs/nlp/constants'

import * as S from './RadioButtonGroup.style'
import { BotType } from 'models/BotType.js'

const options = [
  {
    label: 'Website',
    value: KnowledgeBaseSubTabMap.WEBSITE,
  },
  {
    label: 'File',
    value: KnowledgeBaseSubTabMap.FILE,
  },
  {
    label: 'Text',
    value: KnowledgeBaseSubTabMap.TEXT,
  },
]

interface Props {
  value: string
  onChange: (a: string) => void
}

const RadioButtonGroup: React.FC<Props> = ({ value, onChange }) => {
  return (
    <S.RadioButtonGroup>
      {options.map(option => (
        <S.Option key={option.value} isActive={option.value === value} onClick={() => onChange(option.value)}>
          {option.label}
        </S.Option>
      ))}
    </S.RadioButtonGroup>
  )
}

const mapStateToProps = (state: { activeBot: BotType }) => ({
  activeBot: state.activeBot,
})

export default connect(mapStateToProps)(RadioButtonGroup)
