import { useEffect, useState } from 'react'
import { getUserAttributes } from './userAttributesApi'


export const useSearchUserAttributes = (botId, chatId) => {
  const [userAttributes, setUserAttributes] = useState([])
  const [foundAttributes, setFoundAttributes] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (botId && chatId) {
      getUserAttributes(botId, chatId).then(attributes => {
        // Add lower case names and values once to search by these fields.
        const attributesWithLower = addLowerCaseSearchFields(attributes)
        setUserAttributes(attributesWithLower)
        setFoundAttributes(attributesWithLower)
        setIsLoading(false)
      })
    }
  }, [botId, chatId])

  const addLowerCaseSearchFields = attributes =>
    attributes.map(attribute => ({
      lowerName: attribute.name.toLowerCase(),
      lowerValue: attribute.value.toLowerCase(),
      ...attribute,
    }))

  useEffect(() => {
    if (searchValue && userAttributes) {
      const lowerSearch = searchValue.toLowerCase()
      const found = userAttributes.filter(a => a.lowerName.includes(lowerSearch) || a.lowerValue.includes(lowerSearch))
      setFoundAttributes(found)
    } else {
      setFoundAttributes(userAttributes)
    }
  }, [searchValue])

  return { searchValue, setSearchValue, foundAttributes, isLoading }
}
