export const styles = () => ({
  userNameButton: {
    color: 'var(--color-oxford-blue)',
    border: 'none',
    background: 'none',
    padding: 0,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  userName: {
    color: 'var(--color-oxford-blue)',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
  },
  userNameWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
})
