import React from 'react'
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import classes from '../ApiCallMessage/styles.module.scss'
import { AiActionMessageTab } from './AiActionMessageTab'
import { SparklesIcon } from 'uiKit/icons/SparklesIcon'

interface AIActionCallMessageProps {
  actionName: string
  callArguments: { name: string; value: string }[]
  callResult: string
}

export const AIActionCallMessage = ({
  actionName,
  callArguments,
  callResult,
}: AIActionCallMessageProps): JSX.Element => {
  return (
    <ExpansionPanel className={classes.container}>
      <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
        <SparklesIcon color="#b0b2c0" />
        {actionName}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <AiActionMessageTab callArguments={callArguments} callResult={callResult} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
