import { connect } from 'react-redux'
import { LoaderSmallImage } from 'uiKit/loaders/loaderSmall/LoaderSmallImage'
import { CopyIcon } from 'uiKit/icons/Icons'
import { copyValueToClipboard } from 'helpers/copyValueToClipboard'
import { Tooltip } from '@material-ui/core'
import Search from 'uiKit/Search/Search'
import './userAttributes.css'
import { useSearchUserAttributes } from './useSearchUserAttributes'

const UserAttributes = ({ activeBot, activeUser }) => {
  const { searchValue, setSearchValue, foundAttributes, isLoading } = useSearchUserAttributes(
    activeBot.id,
    activeUser.chatId,
  )

  const handleSearchChange = event => {
    setSearchValue(event.target.value)
  }

  return (
    <div className="d-flex" style={{ height: '100%' }}>
      {isLoading ? (
        <div className="m-auto">
          <LoaderSmallImage />
        </div>
      ) : (
        <div className="d-flex flex-column" style={{ fontSize: '14px', width: '100%' }}>
          <div className="px-4 pb-3 border-bottom">
            <Search value={searchValue} onChange={handleSearchChange} placeholder="Search attributes..." />
          </div>
          <div className="flex-grow-1 px-4" style={{ height: '83%', overflowY: 'scroll' }}>
            {foundAttributes.map((attribute, index) => (
              <div key={attribute.name} className="user-attribute-row my-2 py-2 border-bottom">
                <div className="fw-bolder text-break" style={{ color: 'var(--color-button-secondary)' }}>
                  {attribute.name}
                </div>
                <Tooltip title="Copy attribute value">
                  <div
                    role="button"
                    onClick={() => copyValueToClipboard(attribute.value)}
                    className="font-monospace text-break"
                    style={{ color: 'var(--color-text-secondary)', padding: '1px 0px' }}>
                    {attribute.value}
                    <span className="ps-2">
                      <CopyIcon color="var(--color-text-secondary)" width={16} />
                    </span>
                  </div>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
  activeUser: state.activeUser,
})

export default connect(mapStateToProps)(UserAttributes)
