import React from 'react'
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ResponseTab from 'tabs/flowBuilder/components/ResponseTab/ResponseTab'

import classes from './styles.module.scss'
import { APICallIcon } from 'uiKit/icons/APICallIcon'
import { Warning } from '@material-ui/icons'

interface ApiCallMessageProps {
  apiCallInfo: Record<string, any>
}

export const ApiCallMessage = ({ apiCallInfo }: ApiCallMessageProps): JSX.Element => {
  const firstStatusCodeDigit = apiCallInfo.statusCodeValue?.toString()[0] || '0'
  const isError = firstStatusCodeDigit === '4' || firstStatusCodeDigit === '5'

  return (
    <ExpansionPanel className={classes.container}>
      <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
        {isError && <Warning style={{ color: 'red' }} />}
        <APICallIcon color="#b0b2c0" />
        {apiCallInfo?.request?.method} {apiCallInfo?.request?.url}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <ResponseTab data={apiCallInfo} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
