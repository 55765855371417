import { store } from '../../../index'
import { ITwilioCredentials } from '../reducers/twilioCredentials'

export const GET_TWILIO_CREDENTIALS = 'GET_TWILIO_CREDENTIALS'
export const RESET_TWILIO_CREDENTIALS = 'RESET_TWILIO_CREDENTIALS'

export const setTwilioCredentials = (twilioCredentials: ITwilioCredentials): void => {
  store.dispatch({
    type: GET_TWILIO_CREDENTIALS,
    twilioCredentials,
  })
}

export const resetTwilioCredentials = (): void => {
  store.dispatch({
    type: RESET_TWILIO_CREDENTIALS,
  })
}
