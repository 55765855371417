import React, { ChangeEvent, useState } from 'react'
import { Grid, InputLabel } from '@material-ui/core'

import TabTemplate from '../../../../../uiKit/TabTemplate'
import Input from '../../../../../uiKit/inputs/Input'
import Tooltip from 'uiKit/TooltipInfo/Tooltip'
import classes from './styles.module.scss'

interface AdvancedSettingsProps {
  openAIApiKey: string
  handleChangeAPIKey: (changeEvent: ChangeEvent<HTMLInputElement>) => void
}

export const AdvancedSettings = ({ openAIApiKey, handleChangeAPIKey }: AdvancedSettingsProps): JSX.Element => {
  const [isOpenAdvancedConfig, setIsOpenAdvancedConfig] = useState(false)
  return (
    <>
      <TabTemplate
        withoutBackground
        tab={{
          name: 'Advanced settings',
          description: 'Tweak your chatbot settings to increase the quality of replies',
        }}
        expanded={isOpenAdvancedConfig}
        onChange={() => setIsOpenAdvancedConfig(!isOpenAdvancedConfig)}>
        <Grid direction="column" container>
          <div style={{ display: 'flex', alignItems: 'center', marginBlock: '4px' }}>
            <InputLabel
              htmlFor="apiKey"
              style={{ marginTop: '4px', color: '#3a3f62', fontSize: '18px', fontFamily: 'Lato' }}>
              OpenAI API Key
            </InputLabel>

            <Tooltip id="apiKeyTooltip" tooltipText="Default OpenAI API key will be used if nothing provided" />
          </div>

          <Input
            id="apiKey"
            title="Provide your custom Open AI API key"
            style={{ width: 400 }}
            inputTitleStyles={{ marginTop: '4px' }}
            value={openAIApiKey}
            className={classes.input}
            placeholder="Enter OpenAI API key"
            onChange={handleChangeAPIKey}
          />
        </Grid>
      </TabTemplate>
    </>
  )
}
