import React, { memo, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Message from '../Message'

import { styles } from './styles'
import ConversationIdLine from '../ConversationIdLine'
import CallRecordingPlayer from '../CallRecordingPlayer/CallRecordingPlayer'
import { getRecordingStatuses } from './recordingStatusApi'

const MessageGroup = ({
  classes,
  activeBot,
  activeUser,
  messages,
  date,
  messageIntoView,
  messageId,
  reverse,
  hash,
}) => {
  const [completedRecordingConversationIds, setCompletedRecordingConversationIds] = useState([])

  useEffect(() => {
    getRecordingStatuses(activeBot.id, activeUser.userId).then(recordingStatuses => {
      const completedConversationIds = recordingStatuses
        .filter(rs => rs.recordingStatus === 'COMPLETED')
        .map(rs => rs.conversationId)
      setCompletedRecordingConversationIds(completedConversationIds)
    })
  }, [])

  const setRef = id => {
    return id?.toString() === hash?.substring(1) || id === messageId ? messageIntoView : null
  }

  const renderMessages = messages
    ?.slice()
    ?.reverse()
    ?.filter(msg => msg?.lastEventAt && msg?.lastEventAt?.split('T')[0] === date)

  const lastReactionElementIndex = useMemo(() => {
    let lastIndex
    renderMessages.some((val, index, array) => {
      const reverseIndex = array.length - 1 - index
      const message = JSON.parse(_.get(array, `${reverseIndex}.nativeMessage`)).message
      return message?.enableReaction && (lastIndex = reverseIndex)
    })
    return lastIndex
  }, [renderMessages])

  const isFirstMessageInConversation = (message, index) =>
    message?.conversationId !== renderMessages[index - 1]?.conversationId

  const isLastMessageInConversation = (message, index) =>
    message?.conversationId !== renderMessages[index + 1]?.conversationId

  const isRecordingPlayerPosition = (message, index) => activeBot?.voice && isLastMessageInConversation(message, index)

  const isRecordingCompleted = conversationId => {
    return completedRecordingConversationIds.includes(conversationId)
  }

  const showRecordingPlayer = (message, index) => {
    const isRecordingPosition = isRecordingPlayerPosition(message, index)
    const isCompleted = isRecordingCompleted(message.conversationId)
    const shouldShow = isRecordingPosition && isCompleted
    return shouldShow
  }

  return (
    <div className={classes.container}>
      {renderMessages &&
        renderMessages.map((message, index) => (
          <div ref={setRef(message.chatHistoryId)} key={message.chatHistoryId}>
            {isFirstMessageInConversation(message, index) && (
              <ConversationIdLine conversationId={message.conversationId} />
            )}
            <Message
              messages={messages}
              msg={message}
              reverse={reverse}
              highlight={message.chatHistoryId === messageId}
              showReactionElementText={index === lastReactionElementIndex}
            />
            {showRecordingPlayer(message, index) && (
              <div className="mt-3 mb-5">
                <CallRecordingPlayer conversationId={message.conversationId} />
              </div>
            )}
          </div>
        ))}
    </div>
  )
}

MessageGroup.propTypes = {
  classes: PropTypes.object,
  messages: PropTypes.array,
  date: PropTypes.string,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
  activeUser: state.activeUser,
})

export default memo(withStyles(styles)(connect(mapStateToProps)(MessageGroup)))
