import React from 'react'
import { Tooltip, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import InteractionTimer from '../InteractionTimer/InteractionTimer'
import UserWebsite from '../UserWebsite/UserWebsite'

import { styles } from './styles'
import { CHAT_PLATFORM } from '../../../../constants/chatPlatform'

const UserName = props => {
  const { classes, user, onNameClick } = props
  const isFromWhatsApp = user?.platform === CHAT_PLATFORM.WHATSAPP
  const userWebsiteLocation = user.messages?.find(m => m.websiteLocation !== null)?.websiteLocation

  return (
    <div className={classes.userNameWrap}>
      <div className={classes.userName}>
        <Tooltip title="Open profile" placement="top">
          <button onClick={onNameClick} className={classes.userNameButton}>
            {user.firstName} {user.lastName}
          </button>
        </Tooltip>
        {isFromWhatsApp && user.lastUserActionAt && <InteractionTimer lastInteractionDate={user?.lastUserActionAt} />}
      </div>

      {userWebsiteLocation && <UserWebsite url={userWebsiteLocation} />}
    </div>
  )
}

UserName.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
}

const mapStateToProps = state => ({
  user: state.activeUser,
})

export default withStyles(styles)(connect(mapStateToProps)(UserName))
