import { alertError, createJsonHeaders, customResponseParser, logoutOnRequestOrResponseJson } from '../../../api'
import { SUPPORT_USERS_SIZE } from '../../../constants/userSize'
import { BASE_URL } from '../../../configs'
import { saveActiveUser } from '../actions/activeUser'

export function getAssignedToMeSupportUsers(botId, adminId, searchQuery = '', offset = 0) {
  return fetch(
    // eslint-disable-next-line max-len
    `${BASE_URL}/bot/${botId}/support/status/assigned-to?searchQuery=${searchQuery}&adminId=${adminId}&offset=${offset}&size=${SUPPORT_USERS_SIZE}`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  )
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => customResponseParser(response) || [])
    .catch(alertSomethingIsGoingWrongError)
}

export function getAssignedSupportUsers(botId, searchQuery = '', offset = 0, adminUserId = '') {
  return fetch(
    // eslint-disable-next-line max-len
    `${BASE_URL}/bot/${botId}/support/status/assigned?searchQuery=${searchQuery}&offset=${offset}&size=${SUPPORT_USERS_SIZE}&excludeAdminId=${adminUserId}`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  )
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => customResponseParser(response) || [])
    .catch(alertSomethingIsGoingWrongError)
}

export function getNewRequestSupportUsers(botId, searchQuery = '', offset = 0) {
  // eslint-disable-next-line max-len
  return fetch(
    `${BASE_URL}/bot/${botId}/support/status/open?` +
      `searchQuery=${searchQuery}&offset=${offset}&size=${SUPPORT_USERS_SIZE}`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  )
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => customResponseParser(response) || [])
    .catch(alertSomethingIsGoingWrongError)
}

export function getExpiredRequestSupportUsers(botId, searchQuery = '', offset = 0) {
  return fetch(
    // eslint-disable-next-line max-len
    `${BASE_URL}/bot/${botId}/support/status/expired?searchQuery=${searchQuery}&offset=${offset}&size=${SUPPORT_USERS_SIZE}`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  )
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => customResponseParser(response) || [])
    .catch(alertSomethingIsGoingWrongError)
}

export function getAllSupportUsers(botId, searchQuery = '', offset = 0) {
  return fetch(
    // eslint-disable-next-line max-len
    `${BASE_URL}/bot/${botId}/support/status/chatbot?searchQuery=${searchQuery}&size=${SUPPORT_USERS_SIZE}&offset=${offset}`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  )
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => customResponseParser(response) || [])
    .catch(alertSomethingIsGoingWrongError)
}

export function getClosedSupports(botId, searchQuery = '', offset = 0) {
  return fetch(
    // eslint-disable-next-line max-len
    `${BASE_URL}/bot/${botId}/support/status/closed?searchQuery=${searchQuery}&size=${SUPPORT_USERS_SIZE}&offset=${offset}`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  )
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => customResponseParser(response) || [])
    .catch(alertSomethingIsGoingWrongError)
}

export function getSupportUser(userId, botId) {
  return fetch(`${BASE_URL}/bot/${botId}/support/user/${userId}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => customResponseParser(response) || [])
    .then(user => saveActiveUser(user))
    .catch(alertSomethingIsGoingWrongError)
}

const alertSomethingIsGoingWrongError = () => {
  alertError('Sorry but something is going wrong please ping support!', 5000)
}

export async function getBotUsers(botId) {
  try {
    const response = await fetch(`${BASE_URL}/audience/${botId}/users?size=5&page=0`, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'POST',
      body: JSON.stringify({}),
    })

    return await logoutOnRequestOrResponseJson(response)
  } catch {
    alertError('Sorry but something is going wrong please ping support!')
  }
}
