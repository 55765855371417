import React, { useRef, useState } from 'react'
import { ClickAwayListener } from '@material-ui/core'
import Autocomplete from 'react-autocomplete'

import { LONG_MAX_DIGITS } from '../../../../constants/numeric'

import classes from './styles.module.scss'
import { useDetectMenuPosition } from './useDetectMenuPosition'
import { getSessionStorageValue } from 'helpers/sessionStorage'

interface UserIDsSuggestionsProps {
  userId: string
  validateAndSetUserId: (value: { target: { value: string } }) => void
  activeBotId: number
}

export const userIdStorageSuffix = '-userIdSuggestions'
const maxSuggestionsMenuHeight = 200

export const UserIDsSuggestions = ({
  userId,
  activeBotId,
  validateAndSetUserId,
}: UserIDsSuggestionsProps): JSX.Element => {
  const [focus, setFocus] = useState(false)
  const autocompleteRef = useRef(null)
  const [upward, setUpward] = useState(true)

  const suggestions = getSessionStorageValue(`${activeBotId}${userIdStorageSuffix}`, [])
  useDetectMenuPosition({ autocompleteRef, maxSuggestionsMenuHeight, setUpward, focus })

  const renderItem = (suggest, isHighlighted) => {
    return (
      <div
        style={{ background: isHighlighted ? 'var(--color-hover-overlay-primary)' : 'var(--color-white)' }}
        className={classes.item}
        key={suggest}>
        {suggest}
      </div>
    )
  }

  const renderMenu = items => {
    const style = {
      display: !items.length && 'none',
      bottom: upward && '46px',
      background: '#fff',
      cursor: 'pointer',
      maxHeight: `${maxSuggestionsMenuHeight}px`,
    }

    return (
      <div className={classes.menu} style={{ ...style }}>
        {items}
      </div>
    )
  }

  const onSelect = value => {
    const newValue = {
      target: {
        value,
      },
    }

    validateAndSetUserId(newValue)
    setFocus(false)
  }

  const filterSuggestions = suggest => suggest.toLowerCase().includes(userId?.toLowerCase())

  return (
    <div className={classes.inputWrap} ref={autocompleteRef}>
      <ClickAwayListener onClickAway={() => setFocus(false)}>
        <Autocomplete
          getItemValue={item => item}
          items={upward ? suggestions.filter(filterSuggestions) : [...suggestions].reverse().filter(filterSuggestions)}
          renderItem={renderItem}
          value={userId}
          onChange={e => validateAndSetUserId(e)}
          onSelect={val => onSelect(val)}
          renderInput={props => (
            <input
              {...props}
              className={classes.input}
              placeholder="User ID"
              onFocus={() => setFocus(true)}
              maxLength={LONG_MAX_DIGITS}
            />
          )}
          renderMenu={renderMenu}
        />
      </ClickAwayListener>
    </div>
  )
}
