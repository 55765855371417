export const setCaretAfterNode = (nodeEL: HTMLElement = null): void => {
  const range = document.createRange()
  const sel = window.getSelection()

  if (nodeEL && nodeEL?.parentNode) {
    range.setStartAfter(nodeEL)
    range.collapse(true)
    sel.removeAllRanges()
    sel.addRange(range)
  }
}
