import React, { useMemo, useState } from 'react'
import classes from './styles.module.scss'
import JSONPretty from 'react-json-pretty'
import HeadersPreview from '../HeadersPreview/HeadersPreview'
import { ApiCallGeneral } from '../ApiCallGeneral/ApiCallGeneral'

interface Props {
  data: any
}

type TabType = 'General' | 'Response Headers' | 'Response Body' | 'Request Headers' | 'Request Body'

const ApiCallTabs: Record<TabType, TabType> = {
  General: 'General',
  'Response Headers': 'Response Headers',
  'Response Body': 'Response Body',
  'Request Headers': 'Request Headers',
  'Request Body': 'Request Body',
}

const ResponseTab: React.FC<Props> = ({ data }) => {
  const [selectedTab, setSelectedTab] = useState<TabType>('Response Body')
  const disable = data.request.method === 'GET'

  const handleSelectTab = (tab: TabType) => {
    if (disable && tab === ApiCallTabs['Request Body']) return
    setSelectedTab(tab)
  }

  const statusCodeColor = useMemo(() => {
    switch (data.statusCodeValue?.toString()[0]) {
      case '1':
        return 'var(--color-klein-blue)'
      case '2':
        return 'var(--color-camarone-green)'
      case '3':
        return 'var(--color-gem-blue)'
      case '4':
        return 'var(--color-cinnamon-yellow)'
      default:
        return 'var(--color-tamarillo-red)'
    }
  }, [data?.statusCodeValue])

  return (
    <div className={classes.container}>
      <div className={classes.statusCode} style={{ color: statusCodeColor }}>
        {data.statusCodeValue} {data.response.statusCode || data.response.status}
      </div>
      <div className={classes.body}>
        <div className={classes.tabs}>
          {Object.values(ApiCallTabs).map((tab, index) => (
            <div
              key={index}
              className={selectedTab === tab ? classes.activeTab : classes.tab}
              style={{
                color: disable && tab === 'Request Body' && 'var(--color-oxford-blue)80',
              }}
              onClick={() => handleSelectTab(tab)}>
              {tab}
            </div>
          ))}
        </div>
        <div className={classes.input}>
          {selectedTab === ApiCallTabs.General && <ApiCallGeneral url={data?.request?.url} />}

          {selectedTab === ApiCallTabs['Response Headers'] && <HeadersPreview headers={data?.response?.headers} />}

          {selectedTab === ApiCallTabs['Response Body'] && (
            <JSONPretty
              className={classes.jsonPretty}
              id="json-pretty"
              data={data?.response?.body || 'There is no response body'}
            />
          )}

          {selectedTab === ApiCallTabs['Request Headers'] && <HeadersPreview headers={data?.request?.headers} />}

          {selectedTab === ApiCallTabs['Request Body'] && (
            <JSONPretty
              className={classes.jsonPretty}
              id="json-pretty"
              data={data?.request?.body || 'There is no request body'}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ResponseTab
