import React from 'react'
import { NoChartDataIcon } from '../icons/Icons'

import classes from './styles.module.scss'

interface Props {
  text: string
  containerStyles?: Record<string, string>
}

const NoChartData: React.FC<Props> = ({ text, containerStyles }) => (
  <div className={classes.container} style={containerStyles}>
    <NoChartDataIcon />
    <p className={classes.text}>{text}</p>
  </div>
)

export default NoChartData
