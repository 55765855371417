import { saveContextsSize, addContextsSize, deleteOldContexstSize } from '../actions/contextSize'
import { BASE_URL } from '../../../configs'
import { alertError, alertSuccess, createJsonHeaders, logoutOnRequestOrResponseJson } from '../../../api/index'
import { addContext, removeContext, saveContexts, updateExistedContext } from '../actions/contexts'

export function getContextsPages(botId, page) {
  return fetch(BASE_URL + '/context/bot/' + botId + '?page=' + page, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => {
      if (response.status === 200) {
        return logoutOnRequestOrResponseJson(response).then(json => {
          saveContextsSize(json.contextSize)
          saveContexts(json.contextDTOList)
          return json.contextDTOList
        })
      }
      response.text().then(alertSuccess)
    })
    .catch(() => {
      alertError('Sorry but something going wrong at getting triggers please ping support!')
    })
}

export function getAtomContexts(atomId) {
  fetch(BASE_URL + '/context/atom/' + atomId, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(logoutOnRequestOrResponseJson)
    .then(saveContexts)
    .catch(() => {
      alertError('Sorry but something going wrong at getting triggers please ping support!')
    })
}

export function createNewContext(context, botId, contextSize) {
  fetch(BASE_URL + '/context/bot/' + botId, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(context),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      alertSuccess('Trigger is created successfully.')
      addContextsSize(contextSize)
      addContext(json)
    })
    .catch(() => {
      alertError('Fail. Try it later')
    })
}

export function updateContext(context, botId) {
  fetch(BASE_URL + '/context/bot/' + botId, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'PUT',
    body: JSON.stringify(context),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      alertSuccess('Trigger is updated successfully.')
      updateExistedContext(json)
    })
    .catch(() => {
      alertError('Fail. Try it later')
    })
}

export function deleteContext(contextId, contextSize) {
  fetch(BASE_URL + '/context/' + contextId, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'DELETE',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      alertSuccess('Trigger is deleted successfully.')
      // todo: check json response
      deleteOldContexstSize(contextSize)
      removeContext(contextId)
    })
    .catch(() => {
      alertError('Fail. Try it later')
    })
}
