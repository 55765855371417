import React from 'react'
import classes from './styles.module.scss'

export const ApiCallGeneral = ({ url }: { url?: string }): JSX.Element => {
  return (
    <div className={classes.container}>
      <p className={classes.title}>Request URL:</p>
      {url && (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      )}
    </div>
  )
}
