import { MutableRefObject, useEffect } from 'react'

interface IUseDetectMenuPosition {
  autocompleteRef: MutableRefObject<HTMLDivElement>
  maxSuggestionsMenuHeight: number
  setUpward: (value: boolean) => void
  focus: boolean
}

export const useDetectMenuPosition = ({
  autocompleteRef,
  maxSuggestionsMenuHeight,
  setUpward,
  focus,
}: IUseDetectMenuPosition): void => {
  useEffect(() => {
    if (focus && autocompleteRef.current) {
      const rect = autocompleteRef.current.getBoundingClientRect()
      const windowHeight = window.innerHeight
      const menuHeight = maxSuggestionsMenuHeight
      rect.bottom + menuHeight > windowHeight ? setUpward(true) : setUpward(false)
    }
  }, [focus])
}
