export const getSessionStorageValue = <T>(key: string, defaultValue: T): T => {
  try {
    const storedValue = sessionStorage.getItem(key)
    return storedValue ? (JSON.parse(storedValue) as T) : defaultValue
  } catch (error) {
    return defaultValue
  }
}

export const setSessionStorageValue = (key: string, value: unknown): void => {
  sessionStorage.setItem(key, JSON.stringify(value))
}
