import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Flag from 'react-world-flags'

import { styles } from './styles'
import InteractionTimer from '../InteractionTimer/InteractionTimer'
import { MY_SUPPORT_ICON, OUT_OF_WORKING_HOURS_ICON, SUPPORT_ICON } from '../UserDialogPreview/config'
import moment from 'moment'
import { CHAT_PLATFORM } from '../../../../constants/chatPlatform'

const UserNameBlock = props => {
  const { classes, user, isAssigned, isAssignedToCurrentAdmin } = props
  const supportIcon = (isAssignedToCurrentAdmin && MY_SUPPORT_ICON) || (isAssigned && SUPPORT_ICON)

  const lastMessageDate = moment(user?.lastMessageAt).isSame(new Date(), 'day')
    ? 'Today'
    : moment(user?.lastMessageAt).format('MMM DD')

  const isFromWhatsApp = user?.platform === CHAT_PLATFORM.WHATSAPP

  return (
    <div className={classes.userMainInfo}>
      <div className={classes.userNameWrapper}>
        <p className={classes.name}>
          {user?.firstName} {user?.lastName}
        </p>

        {user?.supportRequest?.status === 'PENDING' && (
          <img src={OUT_OF_WORKING_HOURS_ICON} className={classes.outOfWorkingHoursIcon} alt="not working hours icon" />
        )}

        {supportIcon && <img src={supportIcon} className={classes.supportIcon} alt={'Support Icon'} />}

        {isFromWhatsApp && user.lastUserActionAt && <InteractionTimer lastInteractionDate={user?.lastUserActionAt} />}
        {user?.countryCode && user.countryCode.toLowerCase() !== 'ru' && (
          <Flag className={classes.flag} code={user.countryCode} height="10" title={user.countryCode} />
        )}
      </div>

      <div className={classes.lastMsgTime}>{lastMessageDate}</div>
    </div>
  )
}
UserNameBlock.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(UserNameBlock)
